@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.582),
      rgba(12, 8, 24, 0.904));

  --image-gradient: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863));

  --imp-text-color: #1b8eff;
}

.other-section {
  background-image: url(./Assets/Main/tlo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  /* Ustawia szerokość na 100% dla zawartości */
  max-width: 1200px;
  /* Opcjonalne: Ustaw maksymalną szerokość */
  padding: 20px;
  /* Opcjonalne: Ustaw padding */
}

body,
.home-section {
  background-image: url(./Assets/Main/tlo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* Zmienione na scroll, aby uzyskać ruchome tło */
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  -o-user-select: none;
  /* Opera */
  height: 100%;
  width: 100%;
  /* Ustawia szerokość na 100% dla zawartości */
}


.blue {
  color: white !important;
  font-family: Arial, sans-serif;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #03050d;
}

::-webkit-scrollbar-thumb {
  background: #656ac6;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #656ac6;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */

.dropdown-toggle {
  color: white;
  /* Ustaw kolor tekstu dla rozwijanego przycisku */
}

.dropdown-menu-custom {
  background-color: black;
  /* Kolor tła menu */
  border: 1px solid #ccc;
  /* Opcjonalnie: dodaj obramowanie do menu */
  border-radius: 0;
  /* Zmień zaokrąglenie, aby pasowało do innych elementów */
}

.dropdown-item {
  color: #000;
  /* Kolor tekstu dla elementów w menu */
  padding: 8px 16px;
  /* Ustawienie paddingu dla elementów menu */
}


.brand-name {
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.code {
  font-weight: 800;
  /* Normalna waga */
  background-image: linear-gradient(90deg, rgba(97, 105, 195, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-menu-center {
  display: flex;
  justify-content: flex-start;
  /* Wyrównanie w lewo */
  align-items: center;
  /* Środek w pionie */
  width: 100%;
  /* Użycie pełnej szerokości */
  margin-left: 150px;
  /* Dodatkowe przesunięcie w lewo, dostosuj według potrzeb */
}

.navbar .navbar-collapse {
  justify-content: flex-start;
  /* Wyrównanie zawartości nawigacji do lewej */
}

.sticky {
  background-color: #181a27 !important;
  /* Ustaw kolor tła navbaru, gdy jest sticky */
  transition: all 0.3s ease-out 0s !important;
  box-shadow: white !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
  transition: background-color 0.1s ease-out 0s !important;
  background-color: rgba(24, 26, 39, 0.10) !important;
  z-index: 1000;
}

@media (max-width: 767px) {
  .navbar-collapse {
    display: none;
  }

  .navbar-collapse.show {
    display: block;
  }
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #5f64bc !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: rgba(24, 26, 39, 0.10) !important;
    /* Ustaw przezroczystość na 70% */
  }

  @media (max-width: 767px) {
    .navbar-toggler span {
      transition: none !important;
    }
  }

  .navbar .nav-link,
  .dropdown-basic {
    color: #fff;
    padding: 5px;
    border: none;
    box-sizing: border-box;
  }

  .language-dropdown .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.9);
    /* Adjust color as needed */
    border: 1px solid #ccc;
    /* Optional: border for the dropdown */
    border-radius: 5px;
    /* Optional: rounded corners */
    padding: 10px;
    /* Optional: padding inside the dropdown */
  }

  .language-dropdown .dropdown-item {
    color: #1b8eff;
    /* Set text color for dropdown items */
  }

  .language-dropdown .dropdown-item:hover {
    /* Change background color on hover */
    color: #1b8eff;
    /* Set text color on hover */
  }

  /* Dropdown items */
  .language-dropdown .dropdown-item {
    background-color: rgba(24, 26, 39, 0.9);
    /* Consistent background */
    color: #fff;
    /* Text color */
    padding: 8px 15px;
    /* Consistent padding with nav-link */
    transition: background-color 0.3s;
    /* Smooth transition */
  }

  .language-dropdown .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Change background on hover */
  }

  .language-dropdown .dropdown-toggle {
    background-color: rgba(24, 26, 39, 0.9);
    /* Match navbar */
    /* Text color */
    padding: 8px 15px;
    /* Consistent padding */
    border: none;
    /* Remove border */
    transition: background-color 0.3s;
    /* Smooth transition */
  }

  .language-dropdown .dropdown-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Change on hover */
  }

  /* Styl dla rozwijanego menu */
  .navbar .dropdown-menu {
    background-color: rgba(24, 26, 39, 0.9);
    /* Tło rozwijanego menu */
    border: none;
    /* Usuń domyślne obramowanie */
  }

  /* Styl dla pozycji w rozwijanym menu */
  .navbar .dropdown-item {
    background-color: rgba(24, 26, 39, 0.5);
    /* Tło dla pozycji w rozwijanym menu */
    /* Kolor tekstu dla pozycji w rozwijanym menu */
  }

  /* Styl dla pozycji w rozwijanym menu podczas najechania */
  .navbar .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Kolor tła dla pozycji podczas najechania */
  }

  .navbar .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    /* Kolor tła dla elementów podczas najechania */
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.navbar-nav .nav-link {
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 700 !important;
  color: white !important;
  padding: 8px 15px;
}

.navbar-nav .nav-link:hover {
  font-weight: 400;
  color: #14aaff;
  /* Zmiana koloru po najechaniu kursorem */
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 1.0rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #656ac6;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;

}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 100px !important;
  font-family: 'Poppins', sans-serif;
  /* Użyj standardowej wersji Poppins */
}

@media (max-width: 768px) {
  .home-header {
    text-align: left;
    /* Wyśrodkowanie tekstu dla mniejszych ekranów */
  }

  .home-header .btn {
    margin: 0 auto;
    /* Wyśrodkowanie przycisku */
  }

  .home-header .heading {
    margin: 0 auto;
    /* Wyśrodkowanie przycisku */
  }
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-family: 'Poppins', sans-serif;
  /* Użyj standardowej wersji Poppins */
  font-weight: normal;
  font-weight: 400 !important;
  font-size: 18px;
  color: white;
  font-size: 1.5em !important;
  padding-left: 25px !important;
}

.heading-name {
  font-family: Arial, sans-serif;
  font-weight: normal;
  font-size: 2.5em !important;
  padding-left: 25px !important;
}

.heading-name-v2 span {
  font-family: Arial, sans-serif;
  font-weight: normal;
  padding-left: 15px !important;
  line-height: 1.2;
  /* Możesz dostosować tę wartość */
  display: block;
  /* Sprawi, że każdy span będzie na nowej linii */
}

.main-name {
  color: #1b8eff;
}

.footer-name {
  color: #9e9f9e
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(47, 109, 254, 1) 65%);
  background-clip: text;
  -webkit-background-clip: text;
  /* Dla Safari */
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
  margin-left: 140px;
  display: inline-block;
  margin-Top: "-20px";
  margin-Bottom: "40px";
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(47, 109, 254, 1) 65%);
  background-clip: text;
  -webkit-background-clip: text;
  /* Dla Safari */
  -webkit-text-fill-color: transparent;
  /* Wypełnienie tekstu na przezroczyste */
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
    margin-Top: "-20px";
    margin-Bottom: "40px";
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  background-color: #0f161b !important;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  user-select: none;
  top: -50px;
  left: 50px;
  right: 50px;
  /* Przesunięcie tła w górę */
  /* Dodaje przestrzeń z góry, co sprawia, że tło wydaje się niżej */
  /* Full viewport width */
  /* Adjust positioning */
}

.home-about-description {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-body {
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: normal;
  font-size: 16px;
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social-links {
  display: flex;
  /* Użyj flexbox dla ul */
  flex-wrap: wrap;
  /* Pozwól na zawijanie elementów */
  justify-content: center;
  /* Wyśrodkowanie loga */
  padding: 0;
  /* Usuń domyślne wypełnienie */
  margin: 0;
  /* Usuń domyślny margines */
  list-style: none;
  /* Usuń punkty listy */
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #1b8eff;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #1b8eff;
}

.home-social-icons:hover {
  color: #1b8eff;
  box-shadow: 0 0 5px #1b8eff;
  text-shadow: 0 0 2px #1b8eff;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #1b8eff !important;
}

/* --------- */
/* Footer */
/* --------- */

.footer {
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  background: #090c11 !important;
}

.footer-copywright {
  background: #090c11 !important;
  text-align: center !important;
  font-family: 'Barlow', sans-serif;
  font-weight: 700px;
  font-size: 14px;
}

.footer-body {
  background: #201c24 !important;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  margin-top: auto;
  color: white !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #1b8eff !important;
}

/* --------- */
/* Projects */
/* --------- */

.project-section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
}

.project-card {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  height: auto !important;
  width: auto !important;
  padding: 20px;
  max-width: 100%;
  text-align: left;
  /* Zmniejsz wartość, np. z 5px na 2px */
  color: #959599 !important;
  font-family: 'Poppins-Regular', sans-serif;
}

.project-card-view {
  display: inline-block;
  overflow: visible;
  box-shadow: 0 4px 5px 3px #656ac6 !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: auto !important;
  width: auto !important;
  overflow: hidden;
  /* Prevent overflow */
  /* Ensure the width can grow */
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  -o-user-select: none;
}

.project-card img {
  width: 150px;
  /* Szerokość obrazka */
  height: 150px;
  /* Wysokość obrazka */
  border-radius: 50%;
  /* Okrągły kształt obrazka */
  object-fit: cover;
  /* Wypełnienie kontenera */
}

.stats-card {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  height: 350px !important;
  width: 300px !important;
  padding: 20px;
  max-width: 100%;
  text-align: left;
  /* Zmniejsz wartość, np. z 5px na 2px */
  color: #959599 !important;
  font-family: 'Poppins-Regular', sans-serif;
}

.stats-card-view {
  display: inline-block;
  overflow: visible;
  box-shadow: 0 4px 5px 3px #656ac6 !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 300px !important;
  width: 300px !important;
  overflow: hidden;
  /* Prevent overflow */
  /* Ensure the width can grow */
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  -o-user-select: none;
}

.stats-card-text {
  overflow: visible;
  /* Allow text to wrap */
}

.stats-card img {
  width: 150px;
  /* Szerokość obrazka */
  height: 150px;
  /* Wysokość obrazka */
  border-radius: 50%;
  /* Okrągły kształt obrazka */
  object-fit: cover;
  /* Wypełnienie kontenera */
}

.stats-card-view:hover {
  transform: scale(1.02) !important;
  box-shadow: 0 4px 4px 5px #656ac6 !important;
}

.project-card-text {
  overflow: visible;
  /* Allow text to wrap */
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  box-shadow: 0 4px 4px 5px #656ac6 !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.project-card-text p {
  white-space: nowrap;
  /* Zapobiega łamaniu tekstu */
  overflow: hidden;
  /* Ukrywa nadmiarowy tekst */
}

.btn-primary {
  color: #fff !important;
  background-color: #1b8eff !important;
  border-color: #1b8eff !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #1b8eff !important;
  border-color: #1b8eff !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: white !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}