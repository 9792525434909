.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.description-text {
  white-space: pre-wrap;
  /* Umożliwia łamanie linii */
  overflow: hidden;
  /* Ukrywa nadmiar tekstu */
  max-height: 150px;
  /* Możesz dostosować wysokość */
  text-overflow: ellipsis;
  /* Dodaje wielokropek do długich linii */
}

.review-box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.review-box:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 50px;
  /* Ustal szerokość */
  height: 50px;
  /* Ustal wysokość */
  border-radius: 50%;
  /* Zaokrągl rogi, aby utworzyć okrąg */
  object-fit: cover;
  /* Przytnij obrazek, aby wypełnił okrąg */
  margin-bottom: 10px;
  /* Przestrzeń poniżej awatara */

  /* Dodaj te linie, aby przesunąć avatar */
  margin-left: 10px;
  /* Przesunięcie w prawo (możesz dostosować wartość) */
  margin-top: 10px;
  /* Przesunięcie w dół (możesz dostosować wartość) */
}

.card-image {
  width: 10%;
  /* Ustaw szerokość obrazu na 100% kontenera */
  height: auto;
  /* Utrzymaj proporcje obrazu */
  border-radius: 8px;
  /* Zaokrąglone rogi */
  margin-bottom: 10px;
  /* Dodaj margines poniżej obrazu */
}

.App-link {
  color: #61dafb;
}

.small-title {
  font-size: 5.0rem !important;
  /* użycie !important, jeśli to konieczne */
  margin-bottom: 0;
}

.fade-in {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.responsive-div {
  padding: 30px;
  text-align: left;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .responsive-div {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Dodajemy opóźnienia dla kolejnych elementów */
.fade-in:nth-child(1) {
  transition-delay: 0s;
  /* Pierwszy element, bez opóźnienia */
}

.fade-in:nth-child(2) {
  transition-delay: 0.03s;
  /* Drugi element, opóźniony o 0.3s */
}

.fade-in:nth-child(3) {
  transition-delay: 0.02s;
  /* Trzeci element, opóźniony o 0.6s */
}

.fade-in:nth-child(4) {
  transition-delay: 0.03;
  /* Czwarty element, opóźniony o 0.9s */
}

.circle-button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  /* Przezroczysty środek */
  border: 2px solid white;
  /* Biała obramówka */
  border-radius: 50%;
  /* Zaokrąglony kształt */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  /* Centruje przycisk */
  cursor: pointer;
  /* Zmienia kursor na wskaźnik */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  /* Animacja zmiany koloru */
}

.circle-button i {
  /* Styl dla strzałki (ikony) */
  color: white;
  transition: color 0.3s ease;
  /* Animacja zmiany koloru */
}

.circle-button:hover {
  /* Zmiana tła na biały przy najechaniu */
  border-color: #14aaff;
  /* Zmiana koloru obramówki */
}

.circle-button:hover i {
  color: #14aaff;
  /* Zmiana koloru strzałki na niebieski przy najechaniu */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.language-dropdown .dropdown-toggle::after {
  transition: transform 0.3s ease;
}

.language-dropdown .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
  /* Rotate arrow when expanded */
}

.language-dropdown .dropdown-toggle {
  background-color: transparent !important;
  /* Ustaw tło na przezroczyste */
  border: none !important;
  /* Bez obramowania */
  color: white !important;
  /* Kolor tekstu */
  box-shadow: none !important;
  /* Text color */
  box-shadow: none !important;
  /* No shadow */
  padding: 13px 5px !important;
  /* Adjust padding */
  border-radius: 20px !important;
  /* Rounded corners */
  font-size: 14px;
  /* Font size */
}

.language-dropdown .dropdown-menu {
  border-radius: 8px;
  /* Zaokrąglone rogi menu */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.social-icon {
  color: white;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-card-view:hover .social-icon {
  color: #0c75df;
}

/* Optional styling to animate the card border */
.social-card-view {
  transition: border-color 0.3s ease;
}

.social-card-view:hover {
  border-color: #0c75df;
}

.ts3index-viewer {
  display: block;
  /* Upewnij się, że widget jest wyświetlany */
  margin: 20px auto;
  /* Wyśrodkowanie widgetu */
}

/* FAQ Section Styles */
.faq-section {
  text-align: left;
  /* lub: text-align: start; */
  margin-top: 30px;
  /* Space above the FAQ section */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0) !important;
  /* Background color for the FAQ section */
  border-radius: 8px;
  /* Rounded corners */
  /* Shadow effect */
}

.faq-item {
  margin-bottom: 15px;
  /* Space between each FAQ item */
}

.faq-item div {
  display: flex;
  align-items: center;
  /* Center the content vertically */
  justify-content: space-between;
  /* Space between the question text and arrow icon */
}

.faq-item div:hover {
  background-color: #1b8eff;
  /* Highlight color on hover */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

.faq-item div::after {
  content: '➕';
  /* Plus icon to indicate the collapsed state */
  font-size: 1.5rem;
  /* Size of the icon */
  margin-left: auto;
  /* Push the icon to the right */
  transition: transform 0.3s ease;
  /* Transition for rotating the icon */
}

.faq-item div.open::after {
  content: '➖';
  /* Minus icon to indicate the expanded state */
  transform: rotate(180deg);
  /* Rotate the icon */
}

.faq-item div.open {
  background-color: #1b8eff;
  /* Highlight background when open */
}

.faq-item div.open:hover {
  background-color: #1a8cd3;
  /* Darker shade on hover when open */
}

.faq-item div.open+div {
  display: block;
  /* Show the answer when the item is open */
}

.faq-item div+div {
  display: none;
  /* Hide the answer by default */
}

.home-about-social-links {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.partner-logo {
  margin: 0 15px;
}

@media (max-width: 768px) {
  .partner-logo-img {
    width: 90px;
  }
}

.home-about-social-links {
  display: flex;
  /* Umożliwia wyświetlanie logotypów w jednej linii */
  padding: 0;
  /* Wyłączenie paddingu */
  margin: 0;
  /* Wyłączenie marginesu */
  list-style: none;
  /* Usuń punkty listy */
}

/* Hover state */
.language-dropdown .dropdown-toggle:hover {
  background-color: transparent !important;
  /* Ustaw tło na przezroczyste podczas hover */
  color: white !important;
  /* Kolor tekstu podczas hover */
}

.language-dropdown .dropdown-toggle::after {
  color: white !important;
  /* Ukryj strzałkę rozwijania, jeśli nie jest potrzebna */
}

.language-dropdown .dropdown-menu {
  min-width: 50px;
  /* Minimalna szerokość menu */
  border-radius: 8px;
  /* Zaokrąglone rogi menu */
}

.flag-icon {
  font-size: 20px;
  line-height: 15px;
  display: inline-block;
}

.animated-button {
  margin-left: 25px;
  border-radius: 40px;
  padding: 10px 30px;
  font-size: 1rem;
  position: relative;
  transition: all 0.5s ease;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Add shadow similar to HomeCards */
}

.about-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Umożliwia zawijanie na mniejszych ekranach */
  margin: 20px 0;
  /* Możesz dostosować margines */
}

.form-header {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  font-weight: bold;
  text-align: center;
}

.form-control {
  width: 100%;
  /* Set width to 100% for responsiveness */
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.form-control:focus {
  border-color: #1b8eff;
  box-shadow: 0 0 0 0.2rem rgba(27, 142, 255, 0.25);
}

.about-info>div {
  flex: 1;
  /* Każdy div zajmie równą przestrzeń */
  margin: 10px;
  /* Możesz dostosować margines */
}

@media (max-width: 768px) {
  .about-info {
    flex-direction: column;
    /* Zawartość ułożona w kolumnach na mniejszych ekranach */
    align-items: flex-start;
    /* Wyrównaj do lewej */
  }
}

.hover-button {
  text-align: center;
  border: 1px solid #5f64bc;
  transition: background-color 0.3s ease;
  /* Płynne przejście koloru */
}

.hover-button:hover {
  background-color: #5f64bc;
  color: white;
  /* Opcjonalnie zmień kolor tekstu na biały */
}

.hide-navbar-footer {
  display: none;
}

.animated-button:hover {
  /* Slightly change background color on hover */
  transform: scale(1.05);
  /* Slightly increase size on hover */
}

/* Add this CSS to your stylesheet */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}